
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.user-form {
  .user-form-container {
    margin-bottom: 0 !important;

    .password-container {
      position: relative;

      .rs-info {
        position: absolute;
        right: -2px;
        top: 38px;
      }
    }
  }

  @include centerForm(60, 30, 80, 100);
}
