nav {
  z-index: 1;

  &.navbar {
    .navbar-item {
      margin: 0;
      background: transparent;
      @include border-radius(0);
      @include transition(all 0.3s);
      font-size: $is-size-6;
      text-transform: uppercase;
      color: black;
      padding: 0.5rem 2rem;

      &.is-active,
      &:hover {
        color: black;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          background-color: $red;
          bottom: 0;
          left: 0;
          right: 0;
        }
      }

      &.logo {
        &:hover:after {
          display: none;
        }
      }
    }
  }
}
