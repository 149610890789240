
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
@import './../../../styles/variables/theme.scss';
@import './../../../styles/variables/mixins.scss';

.rs-form {
  fieldset[disabled] .rs-field.rs-input .input-number-format-overlay,
  .rs-field.rs-input.rs-field-disabled .input-number-format-overlay {
    opacity: 0.8;
    cursor: no-drop;
  }

  fieldset[disabled] .rs-field.rs-input input[type='number'],
  .rs-field.rs-input.rs-field-disabled input[type='number'] {
    visibility: hidden;
  }

  input:focus + .input-number-format-overlay {
    border: 1px solid black !important;
  }

  .rs-field {
    &.rs-input {
      // height: 100%;
      // max-height: 48px;

      .input-number-format-overlay {
        width: 100%;
        height: 100%;
        max-height: 38px;
        @include input-style();
        position: absolute;
        top: 0;
        left: 0;
        cursor: text;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        p {
          overflow: hidden;
          margin: 0;
        }
      }

      .reset-button {
        display: flex;
        cursor: pointer;
        opacity: 1;
        font-size: 12px;
        font-weight: bolder;
        align-items: center;
        justify-content: center;
        border-radius: 1px;
        background-color: $grey-bg;
        color: $grey-darker;
        width: 40px;
        max-height: 30px;
        left: unset;
        right: 10px;
        padding-right: 2px;
        &:hover {
          background-color: $grey;
          color: $white;
        }
      }

      .field-wrapper {
        &.mode-view {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        &.required-input {
          input,
          .input-number-format-overlay {
            @include darken-background($lightest-blue, $required-field-darkness);
          }
        }

        &.with-right-label {
          input {
            padding-right: 17px;
          }
        }
      }

      &.has-error {
        .field-wrapper {
          input,
          .input-number-format-overlay {
            border: 1px solid $red;
            background-color: #c3a4a8;
          }
        }
      }

      &.field-issues-error {
        .field-wrapper {
          input,
          .input-number-format-overlay {
            border: 1px solid $red;
          }
        }
      }

      &.field-issues-warning {
        .field-wrapper {
          input,
          .input-number-format-overlay {
            border: 1px solid #ffbf00;
          }
        }
      }

      input {
        @include input-style();
      }
    }
  }
}
