
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.page-home {
  h4 {
    color: $grey-dark;
    margin-top: 0;
  }

  .expandable-list-container {
    ul {
      min-height: 228px;
    }
  }

  $font-size: 15px;
  $ratio: 1.333;
  $mobileRatio: 0.85;
  $tabletRatio: 0.95;
  $headlineLineHeight: 52px;

  $h4LineHeight: 22px;
  $pLineHeight: 24px;

  $h3LineHeight: $h4LineHeight * $ratio;
  $h2LineHeight: $h3LineHeight * $ratio;
  $h1LineHeight: $h2LineHeight * $ratio;
  $h4: $font-size;
  $h3: 22px;
  $h2: 28px;
  $h1: 38px;

  $p: $font-size;
  $small: 0.875em;

  $transition-length: 0.5s;

  .up-shadow {
    position: relative;
    width: 100%;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.35);
    height: 10px;
  }

  .heading {
    color: $grey;
    font-weight: $weight-semibold;
    letter-spacing: 0.05rem;
    font-size: 0.9rem;
  }

  .title {
    letter-spacing: -0.05rem;
  }

  .subtitle {
    padding-bottom: $block-spacing;
  }

  h1,
  h2,
  h4 {
    font-weight: 600;
  }

  h1,
  h2,
  h3,
  h4,
  p {
    margin: 1.5rem 0;
  }

  p,
  small,
  .numbers-p {
    font-weight: 400;
    color: black;
  }

  .rs-no-padding {
    margin: 0;
  }

  .rs-no-margin {
    margin: 0;
  }

  .rs-no-margin-top {
    margin-top: 0;
  }
  .rs-no-margin-bottom {
    margin-bottom: 0;
  }

  h1 {
    font-size: $h1;
    color: $blue-dark;
    line-height: $h1LineHeight;

    @include until($desktop) {
      font-size: 28px;
      line-height: 39px;
    }

    @include until($tablet) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  h2 {
    font-size: $h2;
    line-height: $h2LineHeight;
    color: $grey-darker;
    font-weight: 100;

    @include until($tablet) {
      font-size: $h2 * $mobileRatio;
      line-height: $h2LineHeight * $mobileRatio;
    }

    @include until($desktop) {
      font-size: $h2 * $tabletRatio;
      line-height: $h2LineHeight * $tabletRatio;
    }
  }

  h3 {
    font-size: $h3;
    line-height: $h3LineHeight;
    font-weight: 400;
    color: $grey-darker;

    @include until($tablet) {
      font-size: $h3 * $mobileRatio;
      line-height: $h3LineHeight * $mobileRatio;
    }

    @include until($desktop) {
      font-size: $h3 * $tabletRatio;
      line-height: $h3LineHeight * $tabletRatio;
    }
  }

  h4 {
    font-size: $h4;
    color: rgba(74, 74, 74, 0.5);
    letter-spacing: 0.08em;
    font-weight: 600;
    text-transform: uppercase;
    line-height: $h4LineHeight;

    @include until($tablet) {
      font-size: $h4 * $mobileRatio;
      line-height: $h4LineHeight * $mobileRatio;
    }

    @include until($desktop) {
      font-size: $h4 * $tabletRatio;
      line-height: $h4LineHeight * $tabletRatio;
    }
  }

  .numbers-p {
    font-family: $family-numbers;
  }

  a {
    font-size: $p;
    line-height: $pLineHeight;
    color: $blue;
    cursor: pointer;

    @include transition(all $transition-length);
  }

  p {
    font-size: $p;
    line-height: $pLineHeight;
    color: $grey-darker;

    &.highlighted {
      @include border-radius();
      background-color: $blue-light-faded;
      padding: 15px;
      font-weight: 900;
    }

    &.extra-bold {
      font-family: $family-extra-bold;
      font-size: 14px;
      text-transform: uppercase;
    }

    a {
      @include a-hover-effect();
    }

    .evolution {
      color: $green;
      margin-left: 5px;
    }

    .devolution {
      color: $red;
      margin-left: 5px;
    }
  }

  small {
    font-size: $small;
  }

  .headline-underlined {
    position: relative;
    letter-spacing: 0.08em;
    margin: 3.5rem 0;

    &:after {
      content: '';
      position: absolute;
      height: 2px;
      background-color: $red;
      top: 150%;
      width: 4rem;
      left: 0;
    }

    &.has-text-centered {
      &:after {
        margin-left: -2rem;
        left: 50%;
      }
    }
  }
}
