
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.team-members-list {
  padding: 0;

  &:last-child {
    margin-bottom: 6rem;
  }

  @include until($desktop) {
    padding: 0 2rem;
  }

  .team-members-list-row {
    position: relative;
    padding: 2rem 0;

    &:last-child {
      padding-bottom: 0;
    }
  }

  .team-member-description-block {
    position: relative;
    bottom: 0;
    left: 0;
    background-color: $lightest-blue;
    width: 100%;
    z-index: 1;
    margin: 20px 0;
    @include transition(all $transition-length);

    &.row-description-visible {
      max-height: 1000px;
      padding: 2rem;
    }

    &.row-description-hidden {
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      padding: 0;
      margin: 0;
    }

    .team-member-description-container {
      position: relative;

      p {
        margin: 0;
      }

      .close-container {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        .rs-close-btn {
          position: relative;
          top: -15px;
        }
      }
    }
  }
}
