
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.realstocks-how-it-works-component {
  h3 {
    color: black;
  }

  .realstocks-solution-block-container {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    align-items: center;
    min-height: 424px;

    .realstocks-solution-block {
      background-color: $green;
      max-width: 403px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 392px;

      @include mobile {
        min-height: 0;
      }

      .realstocks-solution-title {
        font-weight: 900;
        color: white;
        text-align: center;
        font-size: 21px;
      }

      .realstocks-solution {
        p {
          text-align: center;
          color: white;

          &.fractionalized-percent {
            font-weight: 900;
            font-size: 36px;
          }
        }
      }
    }
  }

  .cre-loan-block-container {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    align-items: center;

    .cre-loan-block {
      background-color: $grey-light;
      max-width: 403px;
      margin-bottom: 0.5rem;
      width: 100%;

      p {
        text-align: center;
        color: $grey-dark;

        &.no-margin-bottom-mobile {
          @include mobile {
            margin-bottom: 0;
          }
        }

        &.negative,
        &.positive {
          font-size: 28px;
          font-weight: 800;
          margin-bottom: 0;
        }

        &.negative {
          color: $red-dark;
        }

        &.positive {
          color: $green;
        }

        &.cre-loan-title {
          font-size: 21px;
          color: black;
          font-weight: 900;
        }
      }
    }
  }
}
