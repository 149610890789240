
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.privacy-policy {
  padding-bottom: 60px;

  .rs-table-component {
    .privacy-policy-row {
      td {
        &:first-child {
          p {
            font-weight: bold;
          }
        }

        p {
          width: 100%;
          word-break: normal;
        }
      }
    }
  }

  .close-btn {
    margin-top: 20px;
  }

  .close-btn-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
  }

  .rights-of-data {
    p:nth-child(odd) {
      margin-bottom: 0;
    }
    p:nth-child(even) {
      margin-top: 0;
    }
  }

  .download-btn {
    span {
      margin-right: 10px;
    }
  }
}
