
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.inactivity-timer {
  display: flex;
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 5000;
  background: white;
  padding: 10px;
  border: solid 0.1px lightgray;
  user-select: none;

  p {
    margin: 0px;
    font-weight: bold;
  }
}
