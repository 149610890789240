
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.has-navbar-fixed-top {
  .rs-secondary-navigation {
    @include rsdropshadow();
    margin-bottom: 15px;

    &.sticky-to-main-nav {
      position: sticky;
      z-index: 30;
      top: calc(#{$mainNavHeight} - 1px);
    }

    &.sticky-to-main-nav-module {
      position: sticky;
      z-index: 30;
      top: -1px;
    }

    nav {
      background: white;
      display: flex;
      justify-content: center;

      .navbar-start {
        margin: 0;

        @include until($desktop) {
          width: 100%;
        }

        .navbar-menu {
          height: 100%;
          max-width: 100%;

          @include from($tablet) {
            width: 100%;
            display: flex;
            justify-content: center;
          }

          &.rs-secondary-nav-menu {
            @include transition(max-height $transition-length);
          }

          &.rs-secondary-nav-expanded {
            .navbar-item:first-child {
              color: $blue;
              font-weight: bold;
            }

            &:before {
              @include rs-arrow-up();
              top: 15px;
              left: 59%;
              z-index: 1;
            }
          }

          &.rs-secondary-nav-collapsed {
            .navbar-item:first-child {
              color: $blue;
              font-weight: bold;
            }

            &:before {
              @include rs-arrow-down();
              top: 17px;
              top: 15px;
              left: 59%;
              z-index: 1;
            }
          }

          @include desktop {
            .navbar-item {
              &.left-link-item {
                position: absolute;
                left: 0;
                display: flex;
                flex-direction: row;

                svg {
                  margin-right: 10px;
                }
              }

              &.right-link-item {
                position: absolute;
                right: 18px;
                display: flex;
                flex-direction: row;

                svg {
                  margin-right: 10px;
                }
              }
            }
          }

          @include tablet {
            display: flex;
            padding: 0;

            .navbar-item {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 0 1rem;
            }
          }

          @include mobile {
            display: block;
            height: auto;
            padding-top: 0;
            padding-bottom: 0;
          }

          .navbar-item {
            text-transform: none;
            font-size: $is-size-6;
            text-align: center;
            letter-spacing: normal;

            svg {
              font-size: 20px;
              margin: 5px 0;
            }

            @include mobile {
              text-align: center;
              border-bottom: 1px solid $grey-lighter;
              padding: 1rem 0;

              &.sections-item {
                height: 100%;
                margin: 0;

                &:after {
                  display: none;
                }

                &.is-active {
                  border-bottom: 1px solid $grey-lighter;
                }
              }

              &:last-child:not(.is-active) {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }
}

.ReactModal__Body--open {
  .sticky-to-main-nav {
    position: initial !important;
  }
}
