.heading {
  color: $grey;
  font-weight: $weight-semibold;
  letter-spacing: 0.05rem;
  font-size: 0.9rem;
}

.title {
  letter-spacing: -0.05rem;
}

.subtitle {
  padding-bottom: $block-spacing;
}

span {
  &.width100 {
    width: 100%;
    display: block;
  }
}

h1,
h2,
h3,
h4,
hr,
p {
  margin: 1rem 0;

  &.rs-no-margin {
    margin: 0;
  }

  &.rs-no-margin-top {
    margin-top: 0;
  }

  &.rs-no-margin-bottom {
    margin-bottom: 0;
  }
}

.has-text-weight-bold {
  font-family: $family-medium;
}

.has-color-green {
  color: $green;
}

.has-left-border-red {
  border-left: 3px solid $red;
  padding-left: 1rem;
}

.has-color-red {
  color: $red;
}

.has-color-grey-dark {
  color: $grey-dark;
}

p,
small,
.numbers-p {
  color: black;
}

h1 {
  font-size: $is-size-1;
  color: $blue-dark;
  font-weight: bold;

  @include until($widescreen) {
    font-size: $is-size-2;
  }
}

h2 {
  font-size: $is-size-2;
  color: $blue-dark;

  @include until($widescreen) {
    font-size: $is-size-3;
  }
}

h3 {
  font-size: $is-size-3;
  font-weight: 400;
  color: $grey-darker;

  @include until($widescreen) {
    font-size: $is-size-4;
  }
}

h4 {
  font-size: $is-size-4;
  color: $grey-darker;

  @include until($widescreen) {
    font-size: $is-size-5;
  }
}

h5 {
  font-size: $is-size-5;
  color: $grey-darker;
}

.numbers-p {
  font-family: $family-numbers;
}

a {
  font-size: $p;
  line-height: $defaultTextLineHeight;
  color: $blue;
  cursor: pointer;

  @include transition(all $transition-length);
}

p {
  @include paragraph();
}

small {
  font-size: $small;
}

.headline-underlined {
  position: relative;
  letter-spacing: 0.08em;
  margin: 1rem 0;

  &:after {
    content: '';
    position: absolute;
    height: 2px;
    background-color: $red;
    top: 2rem;
    width: 4rem;
    left: 0;
  }

  &.has-text-centered {
    &:after {
      margin-left: -2rem;
      left: 50%;
    }
  }
}
