
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.footer {
  padding: 0;
  margin-top: 15px;

  &.loading {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

  footer {
    padding: 20px;

    p {
      margin: 0;
      color: $grey-dark;

      &.heading {
        color: $grey;
        font-size: 14px;
      }
    }

    .footer-co-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .footer-column-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    @include until($desktop) {
      padding: $section-padding-mobile;

      p {
        text-align: center;
      }
    }
  }
}
