
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.how-it-works-header {
  height: 539px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  background-size: cover;
  background-position: 0 50%;
  padding: 0 2rem;
  background-repeat: no-repeat;

  @include until($desktop) {
    background-position: 0 50%;
    height: 265px;
  }

  @include until($tablet) {
    background-position: 0 50%;
    height: auto;
  }

  .container,
  .columns {
    height: 100%;
  }

  .left-side-container-column {
    height: 100%;
    margin-bottom: 0.5rem;
  }

  .left-side-container {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    p {
      margin-top: 0;
      color: black;
    }
  }
}
