
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.rs-list {
  padding: 1rem 0 1rem 1.2rem;

  &.checkmarked-list {
    padding: 1rem 0;
  }

  li {
    position: relative;

    p {
      margin: 9px 0;
      position: relative;
      left: -5px;
    }
  }

  .rs-list-sublist {
    li {
      margin-left: 0px;
    }
  }

  .rs-list-item-text-container {
    display: flex;
    margin-bottom: 10px;

    .rs-info {
      margin-right: 10px;
    }

    p {
      margin: 0;
    }
  }
}
