
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
@import '../../../../node_modules/bulma/sass/utilities/mixins.sass';
@import './../../styles/variables/theme.scss';
@import './../../styles/variables/mixins.scss';

.form-headline {
  text-align: center;
  margin-bottom: 1rem;
}

.rs-form {
  display: flex;
  flex-direction: column;
  max-width: 756px;
  margin: 0 auto;

  &.full-width {
    max-width: initial;
    width: 100%;
  }

  .rs-field-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
  }

  .rs-form-grouped-item {
    width: 100%;
    border: 1px solid $grey-lighter;
    padding: 10px 0 0 0;
    margin: 20px 0 0 0;

    @include border-radius(5px);

    .columns {
      margin-bottom: 0;
    }

    hr {
      margin: 2px auto;
      max-width: 90%;
    }

    &:not(.last-item) {
      margin-bottom: 20px;
    }

    .rs-form-grouped-item {
      padding: 8px;
      margin: 5px -9px 20px -9px;

      .columns {
        margin: 0;
      }
    }
  }

  &.columns {
    margin: 0 auto;
  }

  .columns {
    margin: -0.2rem -0.75rem;

    .column {
      padding: 0.2rem 0.75rem;
    }
  }
  .field-subline {
    font-size: 12px;
    font-style: italic;
  }

  .error-msg,
  .error-msg p {
    left: 0;
    bottom: -37px;
    width: 100%;
    color: $red;
    text-align: right;
    font-weight: bold;
    font-size: 0.7rem;
    height: 17px;
    padding: 0;

    &.text-align-center {
      text-align: center;
    }

    .message {
      opacity: 0;
      background-color: transparent;
      @include transition(opacity $transition-length);

      &.show {
        opacity: 1;
      }
    }
  }

  .field-issues-msg,
  .field-issues-msg p {
    left: 0;
    bottom: -37px;
    width: 100%;
    color: $red;
    &.warning {
      color: #ffbf00;
    }
    text-align: right;
    font-weight: bold;
    font-size: 0.7rem;
    height: 17px;
    padding: 0;

    &.text-align-center {
      text-align: center;
    }

    .message {
      opacity: 0;
      background-color: transparent;
      @include transition(opacity $transition-length);

      &.show {
        opacity: 1;
      }
    }
  }

  .success-msg {
    color: $green;
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
  }

  .rs-field {
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    max-width: 760px;
    margin: 0 auto;

    &.rs-field-disabled {
      opacity: 0.6 !important;

      p,
      .label-after-input,
      .field-wrapper:after,
      svg:not(.fa-info-circle),
      .input-number-format-overlay {
        opacity: 0.6 !important;
      }
    }

    .field-wrapper {
      .rs-info {
        position: absolute;
        right: -27px;
        top: 50%;
        margin-top: -12px;
      }
    }

    @include until($tablet) {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .rs-label {
        justify-content: flex-start;
        padding-bottom: 2px;
        width: 100%;
      }

      .field-wrapper {
        width: 100%;
      }
    }

    &.has-error {
      .field-wrapper {
        &:not(.is-multiple-select) {
          input,
          textarea,
          select {
            border: 1px solid $red !important;

            &:focus {
              border: 1px solid $grey-darker;
            }
          }

          .error-msg {
            display: block;
            padding-top: 2px;
          }
        }
      }
    }

    .field-wrapper {
      width: 60%;
      position: relative;

      @include until($tablet) {
        width: 100%;
        max-width: 100%;
      }

      &.full-width {
        width: 100%;
      }

      .icon {
        &:not(.is-right) {
          max-width: 16px;
          position: absolute;
          left: 8px;
          top: 9px;
          opacity: 0.8;
          color: inherit;
        }

        &.is-right {
          left: auto;
          right: 8px;
        }

        &.is-left {
          left: 8px;
          right: auto;
        }
      }
    }
  }

  fieldset[disabled] {
    .rs-checkmark {
      opacity: 0.8;
    }

    .rs-select__control.rs-select__control.rs-select__control.rs-select__control.rs-select__control {
      // Specificity wars
      opacity: 0.8;
      cursor: no-drop;
    }

    .rs-field-select .field-wrapper:after {
      display: none;
    }
  }
}

.rs-form-contained {
  padding: 0 0 40px 0;

  .section {
    max-width: 800px;
    margin: 0 auto;
    border-radius: 25px;
  }
}

.switch[type='checkbox'] + label::before,
.switch[type='checkbox'] + label:before {
  top: 0.25rem !important;
  background: $grey-dark !important;
}

.switch[type='checkbox'] + label::after,
.switch[type='checkbox'] + label:after {
  top: 0.45rem !important;
}

.switch[type='checkbox']:checked + label::before,
.switch[type='checkbox']:checked + label:before {
  background: $green !important;
}
