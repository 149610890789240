
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.home-page-services {
  position: relative;
  padding: 32px $page-section-padding-side 32px $page-section-padding-side;
  background: $white-bis;
  margin-top: -10px;

  .services-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    position: relative;
    flex-flow: row wrap;

    .service-block-container {
      .service-block-box {
        padding: 0 1rem;
      }

      @include desktop {
        width: 50%;
        margin: 1rem 0;
      }

      @include widescreen {
        width: 17.5%;
        margin: 0;
      }

      .service-block {
        flex: 1 1;
      }
    }

    .arrow {
      width: 100%;
      height: 20px;

      @include desktop {
        width: 50%;
        // height: 0;
      }

      @include widescreen {
        width: 10%;
        // height: 0;
      }
    }
  }
}
