
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.ReactModalPortal {
  z-index: 220;
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.rs-modal-container,
.ReactModal__Overlay,
.ReactModal__Content {
  outline: none;
}

.ReactModal__Content {
  overflow: hidden;
}

.rs-modal-container {
  padding: 0;
  margin: 0;

  .modal-card-head {
    padding: 0.5rem 1rem;
    background-color: none;
    background: none;

    .modal-close {
      position: absolute;
      top: 10px;
      right: 10px;
      @include transition(background-color $transition-length);

      &:after,
      &:before {
        background-color: $grey-darker;
      }

      &:after {
        height: 16px;
      }

      &:before {
        width: 16px;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }

  .modal-card-body {
    // overflow-y: hidden;
    padding: 20px;
  }
  .modal-body.open-market {
    padding-bottom: 6%;
  }
  .modal-card {
    overflow: visible;
    width: 100%;
  }
}
