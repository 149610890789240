
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.nav-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    position: relative;
    width: 44px;
    height: 44px;
    * {
      fill: $blue;
    }

    &:hover {
      * {
        fill: $blue-dark;
      }
    }

    &.active {
      * {
        fill: $red;
      }
    }

    &.active:hover {
      * {
        fill: $red-dark;
      }
    }

    .badge {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 20px;
      height: 20px;
      background: $red;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-weight: bold;
    }
  }
}

.notification {
  background: transparent !important;
  position: relative !important;
  margin-left: auto !important;

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      font-size: 24px;
    }
  }

  .close-btn-container {
    display: none;
  }

  @include until($desktop) {
    padding-right: 15px;

    .close-btn-container {
      display: flex;
      justify-content: flex-end;

      .rs-close-btn {
        background: none;
        padding: 10px;
      }
    }
  }

  &.rs-expanded {
    @include until($desktop) {
      .notification-popup {
        padding-right: 16px;
        width: 100vw;
        height: 100vh;
        overflow: auto;
        z-index: 999999999999999;
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background: white;
      }
    }
  }

  &.rs-collapsed {
    .navbar-dropdown {
      display: none;
    }
  }

  .notification-popup {
    width: 550px;
    right: 0;
    left: auto;

    padding: 0;

    .tab-header {
      display: flex;
      height: 40px;
      font-size: 14px;

      .actions {
        flex: 40;
      }
      .new {
        flex: 30;
      }
      .all {
        flex: 30;
      }

      div {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        border-bottom: 4px solid lighten($grey, 20%);
        text-transform: none;
        color: $grey-darker;
        letter-spacing: normal;
        background: lighten($grey, 50%);

        &.active {
          color: $blue;
          background: white;
          border-bottom: 4px solid $blue;
          @include rslightshadow();
          z-index: 2;
          font-weight: bold;
        }
      }
    }
  }

  .notifications-content {
    width: 100%;
    height: 100%;
    min-height: 100px;
    max-height: 700px;
    overflow: auto;
    padding: 10px;
    position: relative;
    padding-bottom: 0;
  }

  .notification-item-wrapper {
    min-height: 60px;

    .view-details {
      margin-top: 5px;
      color: $blue;
      font-weight: 900;
      font-size: 12px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .notification-item-wrapper {
    border-bottom: 1px solid $grey-light;
    padding: 20px 10px;

    &:last-of-type {
      border-bottom: 0px solid transparent;
    }
  }

  .notification-item-title {
    font-size: 13px;
    text-transform: initial;
    letter-spacing: normal;
    line-height: normal;
    margin: 0;
    padding-bottom: 10px;
    color: $grey-darker;
  }

  .notification-item-message {
    font-size: 12px;
    color: $blue-dark;
    text-transform: initial;
    margin: 0;
    padding-left: 10px;
    line-height: 16px;

    span {
      font-weight: bold;
    }
  }

  .notification-item-footer {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .created-date {
      margin: 0;
      font-size: 10px;
      letter-spacing: normal;
      color: $grey;
    }

    button {
      padding-bottom: 7px;
      padding-top: 8px;
      font-size: 10px;
      line-height: 10px;
    }
  }

  .empty-notifications {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .action-button {
    margin-right: 10px;
  }

  .offer-table {
    p {
      color: $blue-dark;
      font-size: 10px;
      margin: 0;
      span {
        color: $blue-dark;
        font-weight: 800;
      }
    }
  }
  .view-details {
    color: $blue-dark;
    text-decoration: underline;
    margin: 0;
    margin-right: 20px;

    &:hover {
      text-decoration: none;
    }
  }
}
