
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.rs-nav-link-dropdown-component {
  .navbar-dropdown {
    right: 0;
  }

  &.has-dropdown {
    cursor: pointer;
    margin: 0;

    .dropdown-toggler {
      &:after {
        display: none;
      }
    }

    .navbar-dropdown {
      .navbar-item {
        text-align: center;
        font-weight: normal;
        position: relative;

        &.is-active {
          color: black;

          &:after {
            display: none;
          }
        }

        &:hover:not(.is-active),
        &:active {
          &:after {
            display: none;
          }
        }

        &:hover {
          background: $white-bis;
        }
      }
    }

    .navbar-divider {
      display: block;
    }

    &.rs-collapsed {
      .navbar-dropdown {
        opacity: 0;
        pointer-events: none;
      }

      a.dropdown-toggler:before {
        @include rs-arrow-down();
        z-index: 1;
        top: 12px;
        right: 7px;
        color: $blue-dark;

        @include until($desktop) {
          display: none;
        }
      }
    }

    &.rs-expanded {
      .navbar-dropdown {
        opacity: 1;
        pointer-events: all;
      }

      a.dropdown-toggler:before {
        @include rs-arrow-up();
        z-index: 1;

        color: $blue-dark;

        @include until($desktop) {
          display: none;
        }

        @include from($desktop) {
          top: 12px;
          right: 7px;
        }
      }
    }
  }
}
