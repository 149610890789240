
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
nav {
  &.navbar.rs-main-navigation {
    z-index: 31;
    height: $mainNavHeight;

    & > .container.is-fullhd {
      height: 100%;
    }

    &.drop-shadowed {
      border-bottom: none;
      @include rsdropshadow();
    }

    .is-loading-container {
      width: 100%;
    }

    &:not(.drop-shadowed) {
      border-bottom: 1px solid $grey-lighter;
    }

    .navbar-start {
      justify-content: center;
      align-items: center;
      display: flex;
    }

    @include from($desktop) {
      .navbar-menu {
        height: 100%;

        .navbar-item {
          height: 100%;
        }
      }
    }

    @include until($desktop) {
      .navbar-brand {
        justify-content: space-between;
        display: flex;
        width: 100%;
        z-index: 31;
      }

      .navbar-menu {
        display: flex;
        position: absolute;
        margin: 0;
        width: 100%;
        left: 0;
        right: 0;
        padding: 0 15px;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .navbar-start,
        .navbar-end {
          width: 100%;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;

          .navbar-item {
            width: 100%;
            padding: 0.75rem 0;
            text-align: center;

            &.is-active {
              &:after {
                display: none;
              }
            }
          }
        }

        &.expanded {
          top: $mainNavHeight;
        }

        &.collapsed {
          top: -1000px;
        }
      }
    }

    .rs-main-navigation-content {
      width: 100%;
      padding: 0 30px;
    }

    .rs-main-navigation-content,
    .navbar-brand {
      height: 100%;
      display: flex;
      align-items: center;
      background: white;
    }

    .navbar-start {
      flex-grow: 1;
      justify-content: center;
      display: flex;
    }

    .navbar-end {
      position: relative;
    }
  }
}
