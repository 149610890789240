/* === 2.1 Utilities ===*/

/**
Font sizes
*/

$is-size-0: 3rem;
$is-size-1: 2.25rem;
$is-size-2: 1.75rem;
$is-size-3: 1.5rem;
$is-size-4: 1.2rem;
$is-size-5: 1rem;
$is-size-6: 0.95rem;
$is-size-7: 0.8rem;

$font-size-default: 13px;

/* ==========================================================================
Realbulma variables customization
========================================================================== */
// Brand colors

$red: #aa2227;
$light-red: #96383c;
$red-dark: #7e171b;
$blue: #5a709a;
$blue-vivid: #3c5077;
$blue-mat: #353a40;
$blue-dark: #00215c;
$blue-faded: #adb7cc;
$blue-light-faded: #d6dbe6;
$blue-light-cyan: #cae6ff;
$blue-white: #eef0f2;
$light-blue: #0373b3;
$blue-royal: #0084ff;
$blue-bright: #2857aa;
$lighter-blue: #97a1ae;
$green: #5a979a;
$bright-green: #28a745;
$green-lighter-dark: #d4e6e7;
$green-dark: #487e81;
$fade-blue: #8dc5e6;
$orange: #f9821d;
$violet: #7b0aa2;
$lightest-blue: #dee3e6;
$blue-bis: #f4f8fb;

$yellow: #decb61;
$yellow-light: #fcfaef;
$yellow-dark: #705200;
$grey-darker: #343a40;
$grey-dark: #4c4e50;
$grey: #828286;
$grey-light-readable: #c9caca;
$grey-light: #e9e9e9;
$grey-lighter: #f1f1f1;
$grey-fade: #808080;
$grey-background: #fafafa;
$white-bis: #edf0f2;
$white: #ffffff;
$cccGrey: #ccc;
$grey-bg: #eee;
$off-white: #f8f8f8;
$white-sb: #f5f5f5;
$text-dark: #1a1a1a;
$dark-bg: rgba(48, 63, 92, 0.85);
$shadow-dark: rgba(193, 193, 193, 0.73);

$error-border: #aa2227;
$error-background: #c3a4a8;

$blue-gradient-darker: linear-gradient(135deg, $blue-vivid, $blue-dark);
$gradient-lighter: linear-gradient(180deg, #fafafa, #ffffff);
$gradient-lighter-inv: linear-gradient(0deg, #fafafa, #ffffff);

/// Derived colors
$primary: $red;
$secondary: $blue;

// Typography
$family-sans-serif: Nunito, 'Nunito', sans-serif;
$family-medium: 'Nunito-Medium';
$family-semibold: 'Nunito-SemiBold';
$family-bold: 'Nunito-Bold';
$family-extra-bold: Nunito-ExtraBold, 'Nunito-ExtraBold';
$family-numbers: Roboto, sans-serif;
$size-8: 0.65rem;

// Layouts
$gapPadding: 1.5rem;
$section-padding: 3rem 2rem;
$section-padding-touch: 1rem 1rem;
$page-padding: 0 2rem 32px 2rem;
$section-padding-small: 3rem 1rem;
$section-padding-mobile: 4rem 1rem 1.5rem;
$section-padding-medium: 6rem 3rem;
$section-padding-large: 9rem 3rem;
$page-section-padding-top: 4rem;
$page-section-padding-side: 2rem;
$page-section-padding-bottom: 4rem;
$page-section-padding: $page-section-padding-top $page-section-padding-side $page-section-padding-bottom
  $page-section-padding-side;

$required-field-darkness: 10%;

// Spinner section
$spinner-height: 280px;
$spinner-width: 280px;
$radius: ((($spinner-height + $spinner-width) / 2) * 0.35);
$after-height: ($spinner-height * 0.22);
$after-width: ($spinner-width * 0.08);

// Form variables
$input-border-color: $cccGrey;
$input-background-color: $grey-lighter;
$input-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
$input-error-shadow: inset 0 1px 1px rgba(233, 103, 103, 0.075);
$input-focus-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
$input-width: 240px;
$input-radius: 5px;
$name-input-width: 122px;
$submit-width: $input-width + 34;
$label-color: $grey-dark;
$no-breach: #59969a;
$soft-breach: #e3b204;
$hard-breach: #bf262b;

// Typography

$defaultTextLineHeight: 22px;
$p: $is-size-5;
$small: $is-size-6;

$transition-length: 0.5s;

// CHAT
$chat-bg: #e0e4eb;
$chat-offline: #aa2227;
$chat-online: $green;
$chat-away: $orange;
$chat-own-bg: #00215c;
$chat-own-text: #ffffff;
$chat-other-bg: #00225caa;
$chat-other-text: #ffffff;

// CHATBOT
$chatbot-container-bg: #ffffff;
$chatbot-msg-container-bg: #e0e4eb;
$chatbot-text: #ffffff;
$chatbot-other-bg: #00225caa;
$chatbot-own-bg: #00215c;
$message-options-box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
$message-options-button-hover-color: #012e7c;
$chatbot-selected-color: rgba(255, 255, 255, 0.1);
$chatbot-window-box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.2);

// BULMA NOTIFICATIONS
$notification-padding: 10px 20px;
$notification-radius: 12px;

// LAYOUT
$mainNavHeight: 50px;
$footerHeight: 200px;
$fullContentMinusMainNav: calc(100vh - #{$mainNavHeight});
$pageActionsHeight: 50px;

// MODULE LAYOUT
$moduleNavWidth: 45px;
$expandedModuleNavWidth: 230px;
$moduleContextWidth: 250px;
$moduleContextHorizontalHeight: 200px; // the module context that gets moved to the top in responsive manner

$messagesWindowWidth: 350px;
