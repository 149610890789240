
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.professional-investor-status {
  padding-bottom: 60px;

  .download-btn {
    span {
      font-size: 18px;
    }

    svg {
      margin-right: 10px;
    }
  }

  h2 {
    &:first-child {
      margin-bottom: 0;
    }

    &:nth-child(2) {
      margin-top: 0;
    }
  }

  .close-btn {
    margin-top: 20px;
  }

  .close-btn-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
  }
}
