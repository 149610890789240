
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.mode-view {
  .rs-field {
    &.rs-field-select {
      .field-wrapper {
        &:not(.loading) {
          &:after {
            content: '';
          }
        }
      }
    }
  }
}

.rs-field {
  &.rs-field-select {
    &.rs-field-disabled {
      .field-wrapper {
        &:not(.loading) {
          &:after {
            opacity: 0.8;
          }
        }
      }
    }

    .field-wrapper {
      position: relative;

      &.mode-view {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &:after {
          display: none !important;
        }
      }

      .rs-select--is-disabled {
        pointer-events: all;

        .rs-select__control {
          cursor: no-drop;
          opacity: 0.8;
        }
      }

      &.required-input {
        .rs-select__control {
          @include darken-background($lightest-blue, $required-field-darkness);
        }
      }

      .rs-select__dropdown-indicator {
        display: none;
      }

      .rs-select__indicator-separator {
        display: none;
      }

      svg {
        display: none;
      }

      &.rs-clearable {
        .rs-select__clear-indicator {
          position: relative;
          left: -15px;
          color: $grey-darker;
        }

        svg {
          display: block;
        }
      }

      .rs-select__control {
        @include input-style();
        cursor: pointer;
        height: auto;

        input {
          border: none !important;
          height: 36px;
        }

        &.rs-select__control--is-focused {
          border: 1px solid $grey-darker;
          outline: none;
          box-shadow: none;
        }

        .rs-select__placeholder {
          color: $grey-darker;
        }
      }

      .rs-select__value-container--is-multi {
        margin: 7px 0;
        gap: 5px;

        input {
          height: 22px;
        }
      }

      .rs-select__value-container {
        padding: 0;

        div {
          padding: 0;
          margin: 0;
        }

        .rs-select__multi-value__label {
          @include darken-background($lightest-blue, 30%);
          color: white;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          padding: 0 5px;
        }

        .rs-select__multi-value__remove {
          @include darken-background($lightest-blue, 45%);
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;

          svg {
            display: block;
            color: white;
          }
        }
      }

      .rs-select__menu {
        margin-top: 0;

        .rs-select__menu-list {
          background-color: $lightest-blue;

          .rs-select__option {
            color: $grey-darker;
            cursor: pointer;
            @include transition($transition-length);

            &.rs-select__option--is-disabled {
              color: $grey;
              cursor: not-allowed;
            }

            &.rs-select__option--is-focused,
            &.rs-select__option--is-selected {
              @include darken-background($lightest-blue);
            }
          }
        }
      }

      &:not(.loading) {
        &:after {
          @include rs-arrow-down();
          color: $grey-darker;
          top: calc(50% - 0.8rem) !important;
          right: 14px;
          pointer-events: none;
        }
      }
    }
  }

  &.has-error {
    .field-wrapper,
    .field-wrapper.required-input {
      .rs-select__control {
        border: 1px solid $red;
        background-color: #c3a4a8;
      }
    }
  }

  &.field-issues-error {
    .field-wrapper {
      .rs-select__control {
        border: 1px solid $red;
      }
    }
  }

  &.field-issues-warning {
    .field-wrapper {
      .rs-select__control {
        border: 1px solid #ffbf00;
      }
    }
  }
}

.input-wrapper {
  select + label {
    opacity: 1;
  }

  select {
    @include input-style();
  }
}

.rs-select__menu {
  margin-top: 0;

  .rs-select__menu-list {
    background-color: $lightest-blue;

    .rs-select__option {
      color: $grey-darker;
      cursor: pointer;
      @include transition($transition-length);

      &.rs-select__option--is-focused,
      &.rs-select__option--is-selected {
        @include darken-background($lightest-blue);
      }
    }
  }
}
