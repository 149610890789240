
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.team-member-container {
  padding: 1rem;

  & > .columns {
    @include from($desktop) {
      height: 100%;
      margin: 0;
    }
  }

  .member-img-block {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    justify-content: flex-end;

    &.team-member-expanded {
      &:after {
        opacity: 1;
        @include rs-caret-up();
        font-size: 2.5rem;
        bottom: 0;
        height: 25px;
        left: 48%;
        color: $lightest-blue;
      }
    }

    &.team-member-collapsed {
      &:after {
        opacity: 0;
      }
    }

    .member-img {
      max-width: 100%;
    }

    .member-img-placeholder {
      width: 100%;
      height: 100%;
      background-color: white;
      border: 4px solid $lightest-blue;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 159px;

      p {
        margin: 0;
        color: $grey;
      }
    }
  }

  .team-member-details {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0.75rem 0 0.75rem 0;
    min-height: 100%;

    @include until($desktop) {
      padding: 0.75rem;
    }

    @include until($tablet) {
      min-height: 100%;
      min-height: 150px;
      justify-content: space-between;
    }

    p {
      margin: 0;
      font-size: 14px;

      &.member-name {
        font-size: 16px;
      }
    }

    .linkedin-icon {
      width: 24px;
      height: 24px;
      cursor: pointer;
      @include transition(all $transition-length);
      @include hover-dropshadow($black);
    }

    .team-member-details-footer {
      display: flex;
      margin-top: 10px;

      .details-link {
        position: relative;
        top: 1px;
        padding-right: 1.5rem;
        cursor: pointer;

        p {
          font-weight: 900;
          color: $blue;
          margin-left: 0.7rem;
          @include a-hover-effect();

          &.team-member-collapsed:before {
            @include rs-arrow-down();
            top: 0;
            right: 0.4rem;
          }
          &.team-member-expanded:before {
            @include rs-arrow-up();
            top: 0;
            right: 0.4rem;
          }
        }
      }
    }
  }
}
