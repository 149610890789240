
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.bottom-line-component {
  position: relative;
  background-color: white;

  .container {
    padding: $page-section-padding;
  }

  .bottom-line-column-content {
    padding-left: 4rem;
    padding-right: 4rem;

    @include tablet {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    @include mobile {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .homepage-footer-building {
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;

    img {
      width: 100%;
    }
  }

  p {
    // margin: 5px 0 0 0;
  }

  ul {
    margin-left: 1.3rem;
  }
}
