
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.for-borrowers-for-lenders {
  padding: $page-section-padding;
  background-color: white;

  .list-container {
    display: flex;
    justify-content: center;

    .rs-list {
      max-width: 320px;

      p {
        font-weight: bold;
      }
    }
  }
}
