
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.register-now-component {
  padding: $page-section-padding;
  @include linear-gradient(270deg, $blue 0%, $blue-vivid 100%);
  padding-top: 61px;
  padding-bottom: 61px;
  position: relative;
  top: -7px;

  p {
    color: white;
    text-align: center;
    line-height: 31px;
    margin-top: 0;
    font-weight: 100;
  }

  .register-now-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
