
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.field-wrapper {
  textarea {
    @include input-style();
    width: 100%;
    height: auto;
    padding: 10px;
  }

  .text-area-view {
    width: 50%;
    word-break: break-word;
    text-align: justify;
  }
}
