
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.login-with-bankeid-form {
  .rs-form {
    padding-bottom: 0;
  }

  @include centerForm(65, 20, 70, 100);
}
