
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.rs-image {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0 auto;

  img {
    image-rendering: -webkit-optimize-contrast;
  }

  .zoom-in {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    right: 17px;
    bottom: 29px;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 5px;

    @include border-radius();
    @include transition(all $transition-length);
    @include scaleUpOnHover();

    &:hover {
      background-color: rgba(255, 255, 255, 1);
    }

    img {
      width: 25px;
      height: 25px;
    }
  }
}

.rs-image-modal-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  padding: 0 !important;

  .rs-image-modal {
    width: 100vw !important;

    .modal-card-head {
      height: 47px;
      background: white;
      position: relative;

      .modal-close {
        position: relative;
        top: 6px;
        right: 5px;
      }
    }

    &.rs-modal-container {
      @include until($desktop) {
        padding: 10px;
      }

      .modal-card-head {
        padding: 0;
      }

      .modal-card-body {
        max-height: 90vh;
        padding: 0;

        img {
          width: auto;
          max-height: 500px;
          max-width: 1260px;
        }
      }
    }
  }
}
