
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.login-page {
  padding-top: 20px;

  form {
    padding: 0 40px;
  }

  .help-links {
    margin-top: 20px;
    flex-direction: column;
    display: flex;

    .fa-lock,
    .fa-key {
      margin-right: 8px;
      position: relative;
      top: -1px;
    }

    .or-text {
      margin-top: 0;
      color: $grey;
      font-size: 13px;
    }
  }

  .button {
    &.is-text {
      padding-bottom: 0;
    }
  }

  .alternative-login-btn {
    max-width: 300px;
    margin: 0 auto;

    svg {
      margin-right: 10px;
    }
  }

  .rs-submit-btn-container {
    padding-bottom: 0;
  }

  @include centerForm(60, 20, 70, 100);
}
