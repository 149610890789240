
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.rs-calendar-container {
  &.field-issues-error .field-wrapper {
    input {
      border: 1px solid $red;
    }
  }

  &.field-issues-warning .field-wrapper {
    input {
      border: 1px solid #ffbf00;
    }
  }

  &.has-error .field-wrapper {
    input {
      border: 1px solid $red;
      background-color: #c3a4a8;
    }
  }

  .calendar-container {
    margin: 10px;
    display: flex;
    justify-content: center;

    button {
      padding: 5px 10px;
      min-height: auto;
      font-weight: bold;
    }

    select {
      @include input-style();
      margin: 0 10px;
      font-size: 14px;
      cursor: pointer;
      background-color: white;
      border: 1px solid $grey-light;
    }
  }
}

.rs-form,
form {
  .field-wrapper {
    &.full-width {
      width: 100%;
    }

    &.required-input {
      input {
        @include darken-background($lightest-blue, $required-field-darkness);
      }
    }

    &.has-label-after-input {
      input {
        padding-right: 27px;
      }
    }

    .label-after-input {
      cursor: text;
      position: absolute;
      top: 6px;
      right: 6px;
      font-size: 18px;
      font-weight: bold;
      color: $grey-darker;
    }

    input {
      @include input-style();
    }

    .react-datepicker-wrapper {
      width: 100%;

      &:focus {
        @at-root .rs-calendar-container label {
          font-size: 85%;
          opacity: 1;
          color: $label-color;
        }
      }
    }
  }
}

.rs-datepicker-popper {
  z-index: 9999 !important;
  width: 500px;
}

.react-datepicker__close-icon {
  right: 25px;
  &::after {
    background-color: transparent;
    color: black;
    font-size: 20px;
    font-weight: bold;
    height: 25px px;
    width: 25px;
  }
  &:hover {
    &::after {
      background-color: $grey-light;
    }
  }
}
