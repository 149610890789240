
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.bank-eid-confirmation-page {
  .error-container {
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
      color: $red;
      font-weight: bold;
    }
  }

  .lottie-container {
    & > div {
      width: 150px !important;
      height: 150px !important;
    }
  }

  .success-container {
    padding-top: 60px;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
      color: $green;
      font-weight: bold;
    }
  }
}
