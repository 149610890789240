
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.journey-of-cre-loan {
  padding: $page-section-padding;
  background-color: $white-bis;

  .journey-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;

    .journey-img {
      max-width: 600px;
      width: 100%;
      image-rendering: -moz-crisp-edges; /* Firefox */
      image-rendering: -o-crisp-edges; /* Opera */
      image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
      image-rendering: crisp-edges;
      -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
    }
  }
}
