@use 'sass:color';

/* ==========================================================================
  Realbulma Kit SCSS file: Mixins
  ========================================================================== */

@mixin makeLoadingBlock() {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f6f6;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);

  position: relative;
}

@mixin scale($ratio) {
  -webkit-transform: scale($ratio);
  -moz-transform: scale($ratio);
  -ms-transform: scale($ratio);
  -o-transform: scale($ratio);
  transform: scale($ratio);
}

@mixin scaleUpOnHover() {
  @include scale(0.9);

  &:hover {
    @include scale(1);
  }
}

@mixin vendor-prefix($name, $value) {
  @each $vendor in ('-webkit-', '-moz-', '-ms-', '-o-', '') {
    #{$vendor}#{$name}: #{$value};
  }
}

@mixin page-padding() {
  padding: 2rem 1rem;

  @include until($desktop) {
    padding: $section-padding-touch;
  }
}

// Input
@mixin box-shadow($top, $left, $blur, $spread, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow: inset $top $left $blur $spread $color;
    -moz-box-shadow: inset $top $left $blur $spread $color;
    box-shadow: inset $top $left $blur $spread $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $spread $color;
    -moz-box-shadow: $top $left $blur $spread $color;
    box-shadow: $top $left $blur $spread $color;
  }
}

@mixin darken-background($color, $percentage: 8%) {
  background-color: darken($color, $percentage);
}

@mixin lighten-background($color, $percentage: 2%) {
  background-color: lighten($color, $percentage);
}

@mixin paragraph() {
  font-size: $p;
  line-height: $defaultTextLineHeight;
  color: $grey-darker;

  &.highlighted {
    @include border-radius();
    background-color: $blue-light-faded;
    padding: 15px;
    font-weight: 900;
  }

  &.is-extra-bold {
    font-family: $family-extra-bold;
    font-size: 14px;
    text-transform: uppercase;
  }

  a {
    @include a-hover-effect();
  }

  .evolution {
    color: $green;
    margin-left: 5px;
  }

  .devolution {
    color: $red;
    margin-left: 5px;
  }
}

@-webkit-keyframes autofill {
  0%,
  100% {
    color: #666;
  }
}

@mixin input-style() {
  @include border-radius();
  @include transition(border $transition-length);

  &:disabled {
    opacity: 0.8;
    cursor: no-drop;
  }

  font-size: $p;
  line-height: $defaultTextLineHeight;
  color: $grey-darker;
  width: 100%;
  background-color: $lightest-blue;
  border: 1px solid transparent;
  margin: 0;
  box-sizing: border-box;
  padding: 0 10px;
  height: 38px;

  &:focus {
    border: 1px solid $grey-darker;
  }

  &[type='submit']:disabled {
    cursor: wait;
  }

  &:not([type='checkbox']):focus + label,
  &:not(:placeholder-shown):not([type='checkbox']) + label {
    font-size: 85%;
    transform: translate3d(0, -120%, 0);
    opacity: 1;
    color: $label-color;
    height: 24px;
  }
  &:focus,
  &:hover,
  &:active {
    outline: 0;
  }
  &[type='checkbox'] {
    opacity: 0;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-internal-autofill-selected,
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-internal-autofill-selected {
    -webkit-text-fill-color: $grey-darker !important;
    -webkit-animation-delay: 1s; /* Safari support - any positive time runs instantly */
    animation-delay: 1s; /* Safari support - any positive time runs instantly */
    -webkit-animation-name: autofill;
    animation-name: autofill;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
    -webkit-box-shadow: 0 0 0 50px $lightest-blue inset;
    box-shadow: 0 0 0 50px $lightest-blue inset;
  }
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin border-radius($radius: 5px) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}

@mixin rsdropshadow($color: black) {
  $boxshadow: 0 0.5em 1em -0.125em rgba($color, 0.1), 0 0px 0 1px rgba($color, 0.02);

  box-shadow: $boxshadow;
  -webkit-box-shadow: $boxshadow;
  -moz-box-shadow: $boxshadow;
}

@mixin rslightshadow() {
  @include box-shadow(0px, 0px, 15px, 0px, rgba(0, 0, 0, 0.15));
}

@mixin hover-dropshadow($color) {
  &:hover {
    @include rsdropshadow($color);
  }
}

@mixin a-hover-effect() {
  &:hover {
    text-decoration: underline;
    font-weight: 900;
    color: $blue-dark;
  }
}

// Button
@mixin button($color: $blue, $shadowColor: $blue) {
  @include border-radius();
  @include transition(all, 0.3s);

  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: $defaultTextLineHeight;
  white-space: normal;
  text-transform: uppercase;
  text-align: center;
  font-size: $is-size-6;
  padding: 0 1.5rem;
  background: $color;
  height: auto;
  border: none;
  box-sizing: border-box;
  outline: none;
  width: auto;
  min-width: 90px;

  &:hover,
  &.is-hovered,
  &.is-focused,
  &:focus,
  &:focus-within,
  &:focus:not(:active),
  &:active,
  &.is-focused &:focus:not(.active) {
    @include rsdropshadow($shadowColor);
  }

  &.is-full-width {
    width: 100%;
    max-width: initial;
  }

  &:hover,
  &.is-hovered {
    background: $color;
  }
}

@mixin text-button($color) {
  @include primary-button($color, transparent);

  &,
  &[disabled] {
    background: transparent;
    border: none;
    color: $color;
    text-decoration: none;
  }

  &:hover,
  &.is-hovered,
  &.is-focused,
  &:focus,
  &:focus-within,
  &:focus:not(:active),
  &:active,
  &.is-focused &:focus:not(.active) {
    background: transparent;
    box-shadow: none;
  }
}

@mixin disable-button() {
  opacity: 0.8;
  cursor: not-allowed;
}

@mixin primary-button($color, $inverted: false, $disabled: false) {
  @if $inverted {
    // is primary INVERTED
    &,
    &[disabled] {
      @include button(white, $color);
      border: 1px solid $color;
      color: $color;
    }
  } @else {
    // is primary NOT INVERTED
    &,
    &[disabled] {
      @include button($color, $color);
      border: 1px solid $color;
      color: lighten($color, 50%);
    }
  }

  &:focus,
  &:focus-within,
  &:focus:not(:active),
  &:active,
  &:focus:not(.active) {
    @if $inverted {
      background: white;
    } @else {
      background: darken($color, 15%);
    }
  }

  /**
   * Disabled conditions
  */
  @if $disabled {
    @include disable-button();
  }
  &[disabled] {
    @include disable-button();
  }
  // end disabled conditions
}

// Icon Button
@mixin icon-button($color, $hColor) {
  @include border-radius(none);
  @include transition(all, 0.3s);

  text-align: center;
  font-size: 20px;
  padding: 5px;
  letter-spacing: 0.1rem;
  background: transparent;
  color: $color;
  cursor: pointer;
  font-weight: 900;
  height: auto;
  border-color: transparent;

  &:hover {
    color: $hColor;
  }
}

@mixin invert-button($color, $hcolor, $border, $hborder, $background: white, $hbackground: white) {
  background: $background;
  border: 2px solid $border;
  position: relative;
  box-sizing: border-box;
  top: -1px;
  @include transition(all 0.1s);

  &,
  p {
    color: $color;
  }

  &:hover {
    background: $hbackground;
    border-color: $hborder;
    &,
    p {
      color: $hcolor;
    }
  }

  &:disabled {
    border-color: $grey-light;

    &,
    p {
      color: $grey-light-readable;
    }
  }

  &:focus,
  &:focus-within,
  &:focus:not(:active),
  &:active,
  &:focus:not(.active) {
    background-color: $hbackground;
    border-color: $hborder;

    &,
    p {
      color: $hcolor;
    }
  }
}

/// Test if `$value` is a valid direction
/// @param {*} $value - Value to test
/// @return {Bool}

@function is-direction($value) {
  $is-keyword: index(
    (
      to top,
      to top right,
      to right top,
      to right,
      to bottom right,
      to right bottom,
      to bottom,
      to bottom left,
      to left bottom,
      to left,
      to left top,
      to top left
    ),
    $value
  );
  $is-angle: type-of($value) == 'number' and index('deg' 'grad' 'turn' 'rad', unit($value));

  @return $is-keyword or $is-angle;
}

/// Convert a direction to legacy syntax
/// @param {Keyword | Angle} $value - Value to convert
/// @require {function} is-direction
/// @require {function} convert-angle
/// @throw Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.;
@function legacy-direction($value) {
  @if is-direction($value) == false {
    @error "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.";
  }

  $conversion-map: (
    to top: bottom,
    to top right: bottom left,
    to right top: left bottom,
    to right: left,
    to bottom right: top left,
    to right bottom: left top,
    to bottom: top,
    to bottom left: top right,
    to left bottom: right top,
    to left: right,
    to left top: right bottom,
    to top left: bottom right
  );

  @if map-has-key($conversion-map, $value) {
    @return map-get($conversion-map, $value);
  }

  @return 90deg - $value;
}

/// Mixin printing a linear-gradient
/// as well as a plain color fallback
/// and the `-webkit-` prefixed declaration
/// @access public
/// @param {Keyword | Angle} $direction - Linear gradient direction
/// @param {Arglist} $color-stops - List of color-stops composing the gradient
@mixin linear-gradient($direction, $color-stops...) {
  // Direction has been omitted and happens to be a color-stop
  @if is-direction($direction) == false {
    $color-stops: $direction, $color-stops;
    $direction: 180deg;
  }

  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}

@mixin rs-before-after-icon() {
  font-family: 'Font Awesome 5 Free';
  display: inline-block;
  font-weight: 900;
  position: absolute;
  font-size: 1.1rem;
  color: $blue;
  display: block;
  opacity: 1;
}

// down and up arrows
@mixin rs-arrow-down() {
  @include rs-before-after-icon();

  content: '\f107';
  right: 0;
  top: 0.7rem !important;
}

@mixin rs-checkmark() {
  @include rs-before-after-icon();

  content: '\f00c';
  right: 0;
  top: 0;
}

@mixin rs-arrow-up() {
  @include rs-before-after-icon();
  content: '\f106';
  right: 0;
  top: 1.9rem;
}

@mixin rs-caret-down() {
  @include rs-before-after-icon();
  content: '\f0d7';
}

@mixin rs-caret-up() {
  @include rs-before-after-icon();
  content: '\f0d8';
}

// end navigation dropdown arrows

@mixin scrollbars($size, $background, $trackColor, $trackHColor) {
  // For Google Chrome
  ::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  ::-webkit-scrollbar-track {
    background: $background;
  }
  ::-webkit-scrollbar-thumb {
    background: $trackColor;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: $trackHColor;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $background;
    scrollbar-track-color: $trackHColor;
  }
}

// Browser Prefixes
@mixin transform($transforms) {
  -webkit-transform: $transforms;
  -moz-transform: $transforms;
  -ms-transform: $transforms;
  transform: $transforms;
}

// Rotate
@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}

@mixin translate($x, $y) {
  -webkit-transform: translate($x, $y);
  -moz-transform: translate($x, $y);
  -ms-transform: translate($x, $y);
  -o-transform: translate($x, $y);
  transform: translate($x, $y);
}

@mixin centerForm(
  // defaults as defined in RsForm.scss
  // by default forms are not centered. Adjust these values to center form
  // in your specific use case. Example in UserForm.scss
  $fieldsWitdhPercent: 60,
  $labelWidthPerccent: 40,
  $fieldErrorWidthPercent: 100,
  $fieldErrorWidthPercentSmallScreen: 100
) {
  .rs-form .rs-field .field-wrapper {
    width: #{$fieldsWitdhPercent + '%'};
  }

  .rs-form .rs-field .rs-label {
    width: #{$labelWidthPerccent + '%'};
  }

  @media (min-width: 768px) {
    .rs-form .error-msg {
      width: #{$fieldErrorWidthPercent + '%'};
    }
  }

  @media (max-width: 768px) {
    .rs-form .error-msg {
      width: #{$fieldErrorWidthPercentSmallScreen + '%'};
    }
  }

  .rs-form .form-error.error-msg {
    width: 100%;
  }
}
