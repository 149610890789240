
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.something-went-wrong-page {
  padding-top: 60px;
  padding-bottom: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 50vh;

  .go-back-btn {
    margin-top: 20px;
  }

  .lottie-container {
    div[aria-label='animation'] {
      width: 591px !important;
      height: auto !important;
      max-width: 100%;
    }
  }
}
