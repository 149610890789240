/* ==========================================================================
Layout
========================================================================== */
html {
  height: 100vh;
  overflow-y: auto;
  font-size: $font-size-default;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body {
  margin: 0;
  padding: 0;
  scroll-snap-type: y mandatory;
  height: 100vh;
  scroll-behavior: auto;

  &.has-navbar-fixed-top {
    padding-top: $mainNavHeight;
  }

  @include scrollbars(6px, $grey-light, $lighter-blue, $lighter-blue);

  margin: 0;
  font-family: $family-sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.page-container {
  min-height: 65vh;
}

// Header
@media screen and (min-width: 1025px) {
  .navbar.is-spaced {
    padding: 1rem 6rem;
  }
}

// Footer
footer {
  a {
    color: $grey-dark;
    line-height: 2rem;
  }
  .heading {
    padding-bottom: $block-spacing;
  }
  .address {
    margin-top: $block-spacing;
  }
  .copyright {
    font-size: $size-7;
    margin-top: $block-spacing;
    color: $grey;
    text-align: center;
  }
}

// Sidebar mobile
.sidebar-wrapper {
  max-width: 295px;
  margin-bottom: 50px;
}
@media (max-width: 991px) {
  .sidebar-wrapper {
    width: 100%;
    max-width: 992px;
    margin-bottom: 0;
  }
}
.sidebar-wrapper > ul li {
  font-size: 1.8rem;
  font-weight: 500;
}
.sidebar-wrapper > ul > li {
  border-top: 1px solid #e9e7e7;
  font-weight: 500;
}
.sidebar-wrapper nav > ul {
  font-family: Montserrat, sans-serif;
  margin-bottom: 0;
}
.sidebar-wrapper nav > ul li {
  font-size: 1.8rem;
  font-weight: 500;
}
.sidebar-wrapper nav > ul > li {
  border-top: 1px solid #e9e7e7;
  font-weight: 500;
}
.sidebar-wrapper nav > ul > li:first-child {
  border: 0;
}
.sidebar-wrapper nav > ul > li > a {
  color: #1f1a17;
  display: block;
  padding: 20px 20px;
}
.sidebar-wrapper nav > ul > li > a:hover {
  color: #fff;
  background: #ed145b;
}

button {
  outline: none;
}

select#sub-menu {
  width: 100%;
  margin: 0rem 0 2rem;
  padding: 0.5rem;
  background: url(https://raw.githubusercontent.com/solodev/mobile-sidebar-navigation/master/drop-arrow.png) right
    no-repeat;
  background-color: $white;
}
select#sub-menu,
select#sub-menu > option {
  font-size: 1rem;
}

.loading-spinner-wrapper {
  display: none;
}
[v-cloak] {
  display: none;
  &.loading-spinner-wrapper {
    display: block;
  }
}

.no-padding {
  padding: 0;
}

.no-padding-top {
  padding-top: 0;
}

.no-padding-bottom {
  padding-bottom: 0;
}

.no-margins {
  margin: 0;
}

.input-page {
  padding: $page-padding;
  min-height: 67vh;

  .section {
    padding: 1rem;
  }
}

.rs-section {
  padding: $section-padding;

  @include until($desktop) {
    padding: $section-padding-touch;
  }
}

.centered-column {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.compact {
  // .compact layout
  .section {
    padding: 0.7rem 0;
  }

  .columns:first-child {
    margin-top: 0;
  }

  .column {
    padding: 0.25rem 0.75rem;
  }

  .error-msg {
  }

  // .compact typography
  .title {
    font-size: 1.5rem;
  }

  .table {
    td {
      font-size: 0.9rem;
    }
  }

  .input-wrapper {
    input,
    select,
    textarea,
    label {
      font-size: 0.9rem;
    }
  }

  .error-msg,
  .error-msg p {
    font-size: 0.7rem;
    min-height: 17px;
  }

  // .compact spacing
  .title:not(:last-child),
  .subtitle:not(:last-child) {
    padding: 0 0;
    margin: 0;
  }
}

.fallback-container {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rs-route {
  min-height: calc(
    100vh - #{$mainNavHeight + $footerHeight}
  ); // - $mainNavHeight - $footerHeight (see in themes.scss variables defined);
}

.lottie-container {
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.page-wide {
    height: $fullContentMinusMainNav;

    div {
      position: relative;
      top: -100px;
    }
  }

  h3 {
    position: relative;
    top: -140px;
    margin: 0;
  }
}

.container {
  &:not(.is-max-desktop):not(.is-max-widescreen) {
    &.is-fullhd {
      @include until($fullhd) {
        max-width: 100%;
      }

      @include from($fullhd) {
        max-width: 1920px;
      }
    }
  }
}

// static pages font sizes

.static-page {
  $ratio: 1.333;
  $mobileRatio: 0.85;
  $tabletRatio: 0.95;
  $headlineLineHeight: 52px;
  $h4LineHeight: 22px;
  $pLineHeight: 24px;
  $h3LineHeight: $h4LineHeight * $ratio;
  $h2LineHeight: $h3LineHeight * $ratio;
  $h1LineHeight: $h2LineHeight * $ratio;
  $h4: 15px;
  $h3: 22px;
  $h2: 28px;
  $h1: 38px;

  p {
    font-size: 15px;
    line-height: $h4LineHeight;
  }

  h1 {
    font-size: $h1;
    color: $blue-dark;
    line-height: $h1LineHeight;

    @include until($desktop) {
      font-size: 28px;
      line-height: 39px;
    }

    @include until($tablet) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  h2 {
    font-size: $h2;
    line-height: $h2LineHeight;
    color: $grey-darker;
    font-weight: 100;

    @include until($tablet) {
      font-size: $h2 * $mobileRatio;
      line-height: $h2LineHeight * $mobileRatio;
    }

    @include until($desktop) {
      font-size: $h2 * $tabletRatio;
      line-height: $h2LineHeight * $tabletRatio;
    }
  }

  h3 {
    font-size: $h3;
    line-height: $h3LineHeight;
    font-weight: 400;
    color: $grey-darker;

    @include until($tablet) {
      font-size: $h3 * $mobileRatio;
      line-height: $h3LineHeight * $mobileRatio;
    }

    @include until($desktop) {
      font-size: $h3 * $tabletRatio;
      line-height: $h3LineHeight * $tabletRatio;
    }
  }

  h4 {
    font-size: $h4;
    color: rgba(74, 74, 74, 0.5);
    letter-spacing: 0.08em;
    font-weight: 600;
    text-transform: uppercase;
    line-height: $h4LineHeight;

    @include until($tablet) {
      font-size: $h4 * $mobileRatio;
      line-height: $h4LineHeight * $mobileRatio;
    }

    @include until($desktop) {
      font-size: $h4 * $tabletRatio;
      line-height: $h4LineHeight * $tabletRatio;
    }
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
