/* ==========================================================================
Fonts
========================================================================== */

@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fonts/roboto/Roboto-Regular.eot');
  src: url('../../assets/fonts/roboto/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/roboto/Roboto-Regular.woff2') format('woff2'),
    url('../../assets/fonts/roboto/Roboto-Regular.woff') format('woff'),
    url('../../assets/fonts/roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('../../assets/fonts/nunito/Nunito-Regular.eot');
  src: url('../../assets/fonts/nunito/Nunito-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/nunito/Nunito-Regular.woff2') format('woff2'),
    url('../../assets/fonts/nunito/Nunito-Regular.woff') format('woff'),
    url('../../assets/fonts/nunito/Nunito-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito-ExtraBold';
  src: url('../../assets/fonts/nunito/Nunito-ExtraBold.eot');
  src: url('../../assets/fonts/nunito/Nunito-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/nunito/Nunito-ExtraBold.woff2') format('woff2'),
    url('../../assets/fonts/nunito/Nunito-ExtraBold.woff') format('woff'),
    url('../../assets/fonts/nunito/Nunito-ExtraBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito-Bold';
  src: url('../../assets/fonts/nunito/Nunito-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Nunito-Medium';
  src: url('../../assets/fonts/nunito/Nunito-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Nunito-SemiBold';
  src: url('../../assets/fonts/nunito/Nunito-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
