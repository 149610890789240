/* ==========================================================================
Icons
========================================================================== */
.icon {
  display: inline-block;
}

// Loading Spinner Section
.loading-spinner {
  transform: scale(0.2);
  color: gray;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  height: $spinner-height;
  width: $spinner-width;
  div {
    transform-origin: round($spinner-width / 2) round($spinner-height / 2);
    animation: loading-spinner 1.2s linear infinite;
    &:after {
      content: ' ';
      display: block;
      position: absolute;
      top: round($spinner-height / 2 - $radius - $after-height / 2);
      left: round($spinner-width / 2 - $after-width / 2);
      width: round($after-width);
      height: round($after-height);
      border-radius: 20%;
      background: #000;
    }

    @for $i from 0 through 11 {
      &:nth-child(#{$i + 1}) {
        transform: rotate(($i * 30) + deg);
        animation-delay: (($i / 10) - 1.1) + s;
      }
    }
  }
}

@keyframes loading-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
