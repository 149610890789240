
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
/* ==========================================================================
Realbulma Kit SCSS file: About Us styling
========================================================================== */

.about-us-page {
  .headline-underlined {
    margin: 4rem 0;
  }
}
