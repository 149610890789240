
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.rs-close-btn {
  cursor: pointer;
  border: none;
  outline: none;
  color: $blue;
  @include transition(all $transition-length);
  @include scaleUpOnHover();
}
