
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
@import './../../../styles/variables/theme.scss';
@import './../../../styles/variables/mixins.scss';

.rs-info {
  margin: 0 5px;

  .rs-info-tooltip {
    max-width: 450px;
    z-index: 9999999;
  }
  p {
    margin: 0;
  }

  .__react_component_tooltip {
    z-index: 9999999;
    overflow: visible;
    max-width: 350px !important;

    &.type-info {
      max-width: 350px !important;
    }
  }

  p {
    margin: 0;
    @include paragraph();
    letter-spacing: normal;
    text-transform: none;
  }

  .fa-info-circle {
    color: $grey-darker;

    svg {
      width: 15px;
    }
  }

  &.info {
    .fa-info-circle {
      color: $blue;
    }
  }

  &.dark {
    .fa-info-circle {
      color: $grey-darker;
    }
  }

  &.success {
    .fa-info-circle {
      color: $green;
    }
  }

  &.error {
    .fa-info-circle {
      color: $red;
    }
  }

  &.warning {
    .fa-info-circle {
      color: $orange;
    }
  }

  .type-light {
    @include rsdropshadow();

    &,
    p {
      color: $grey-darker;
    }
  }

  .type-warning {
    background: $orange;

    &::after {
      border-top-color: $orange !important;
    }

    &,
    p {
      color: white;
    }
  }

  .type-error {
    background: $red;

    &::after {
      border-top-color: $red !important;
    }

    &,
    p {
      color: white;
    }
  }

  .type-info {
    background: $blue;

    &::after {
      border-top-color: $blue !important;
    }

    &,
    p {
      color: white;
    }
  }

  .type-dark {
    &,
    p {
      color: white;
    }
  }

  .type-success {
    background: $green;

    &::after {
      border-top-color: $green !important;
    }

    &,
    p {
      color: white;
    }
  }
}
