
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
@import './../../../../node_modules/bulma/sass/utilities/mixins.sass';
@import './../../styles/variables/theme.scss';
@import './../../styles/variables/mixins.scss';

.loading-block {
  width: 100%;
  height: 100%;
  min-height: 40px;

  .loading {
    @include makeLoadingBlock();
    height: 100%;

    div {
      background: #fff;
      height: 6px;
      left: 0;
      position: absolute;
      right: 0;
    }
  }
}
