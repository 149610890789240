
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.rs-form {
  .rs-checkbox-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    @include transition(all $transition-length);

    &.is-fullwidth {
      .rs-label {
        width: 100%;
      }

      .field-wrapper {
        width: auto;
      }
    }

    .rs-field-content {
      margin-bottom: 10px;
    }

    .view-check {
      svg {
        font-size: 20px;

        &.fa-check-square {
          color: $green;
        }

        &.fa-square {
          color: $grey;
        }
      }
    }

    &:hover {
      // background: rgba(0, 0, 0, 0.02);
    }

    .rs-label {
      cursor: pointer;
    }

    .field-wrapper {
      position: relative;

      &.mode-view {
        display: flex;
        align-items: center;
      }

      .rs-checkbox-inner-field {
        display: flex;
      }

      p {
        margin: 0;
        position: relative;
        top: 1px;
        left: 5px;

        a {
          font-weight: 900;
          margin-left: 4px;
        }
      }

      input:checked ~ .rs-checkmark {
        &:after {
          @include rs-checkmark();
          color: $green;
          top: 1px;
          right: 5px;
        }
      }

      .rs-checkmark {
        @include border-radius();
        background-color: $lightest-blue;
        width: 25px;
        height: 25px;
        cursor: pointer;
        pointer-events: none;
        position: relative;

        &.disabled {
          opacity: 0.8;
        }

        &:after {
          content: '';
        }
      }
    }

    .no-label-container {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      padding-right: 15px;
      position: relative;
    }

    .children {
      a {
        cursor: pointer;
        margin-left: 5px;
      }
    }
  }
}
