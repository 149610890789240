
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.expandable-list-container {
  margin: 1rem;
  width: 500px;

  @include mobile {
    width: 80%;
    margin: 0 auto;
  }

  p {
    margin: 0;
  }

  .expandable-list-component.list-only {
    background: $white;
  }
  .expandable-list-component.bottom-panel {
    background: $white;
  }

  .expandable-list-component {
    background-color: $white-bis;

    @include mobile {
      width: 100%;
    }

    .expandable-list-component-content {
      &.bottom-panel > ul {
        padding: 1rem;
        min-height: auto;
      }
    }

    .expandable-list-component-header {
      padding: 0.1rem 2rem 0 2rem;
    }

    .expandable-list-component-content > ul > li {
      padding-bottom: 0.5rem;
    }

    .expandable-list-component-content {
      @include transition(all $transition-length);
      overflow: hidden;

      @include tablet {
        margin-bottom: 4rem;
      }

      & > ul {
        padding: 0 1rem 0 1rem;

        @include mobile {
          padding: 0 1rem 4rem 1rem;
        }
      }

      ul {
        p {
          color: $grey-dark;
        }

        li {
          ul {
            display: inline;
          }
        }
      }

      @include until($desktop) {
        padding: 0 1rem 1rem;
      }
    }

    &.expanded {
      .expandable-list-component-footer {
        .expand-icon {
          transform: rotate(180deg) scaleX(-1);
        }
      }
    }

    .expandable-list-component-footer {
      cursor: pointer;
      position: relative;
      padding: 0 1rem;
      background-color: $blue-light-faded;

      @include transition(background-color $transition-length);

      .columns {
        margin: 0;

        .column {
          &:first-child {
            padding-bottom: 0;
          }

          &:last-child {
            padding-top: 0;
          }
        }
      }

      img {
        @include transition(transform $transition-length);
        max-height: 20px;
      }

      .footer-note {
        opacity: 0.8;
        font-size: 0.75rem;
        padding: 1.5rem 1rem 1.5rem;
        display: block;
        position: absolute;
        top: -4.8rem;

        @include until($desktop) {
          bottom: 6rem;
        }

        @include mobile {
          top: -5.5rem;
          font-size: 0.64rem;
        }
      }

      .expandable-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3 {
          margin: 0.5rem 0;
          text-transform: uppercase;
          font-weight: 800;

          @include mobile {
            line-height: 20px;
            padding: 1rem 0;
          }
        }
      }
    }

    h3 {
      font-size: $is-size-5;
      line-height: 42px;
      font-weight: 800;
      text-transform: uppercase;
      font-family: $family-extra-bold;
    }

    hr {
      background-color: $cccGrey;
      height: 1px;
    }

    .expandable-list-el-row {
      display: flex;
      padding-bottom: 1rem;

      img {
        max-height: 17px;
        margin-right: 0.5em;
        position: relative;
        top: 3px;
      }
    }
  }
}
