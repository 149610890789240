
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.verify-form {
  max-width: 300px;

  .resend-code {
    @media only screen and (max-width: 320px) {
      margin-left: -40px;
    }
  }
}
