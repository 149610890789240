
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.deposit-alternative-home {
  padding: $page-section-padding;
  background-color: $white-bis;

  .deposit-alternative-service-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .service-block-container {
    margin: 0;
  }

  .product-vision-headline {
    margin-bottom: 0;
  }

  .product-vision-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-top: 20px;
    padding-bottom: 1.5rem;

    p {
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
