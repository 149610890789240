
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.column-service-container {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @include mobile {
    margin-bottom: $gapPadding;
  }

  .section-icon {
    width: 100%;
    max-width: 62px;
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
  }

  .service-icon-container {
    min-height: 4.5rem;
  }

  .section-number {
    position: absolute;
    top: -36px;
    left: 50%;
    font-size: 7rem;
    font-weight: bold;
    opacity: 0.06;
    margin-left: -87px;
    font-family: $family-numbers;
  }
}
