
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
@charset "utf-8";

/**
Order is important
*/

@tailwind base;
@tailwind components;

@import '../../../node_modules/animate.css/animate.min.css';
@import '../../../node_modules/bulma/bulma.sass';
@import '../../../node_modules/bulma-extensions/src/sass/index.sass';
@import '../../../node_modules/react-toastify/scss/main.scss';

@import './global/all.scss';

@tailwind utilities;
