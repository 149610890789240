
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.signup-page {
  @include page-padding();

  h1 {
    margin: 0;
  }

  .headline {
    padding-bottom: 20px;

    p {
      margin: 0;
    }

    .left-side-button-container {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
    }
  }

  .or-text {
    color: $red;
    margin: 0 20px;
  }

  .user-signup-form {
    .rs-submit-btn-container {
      justify-content: center;
      margin-bottom: 20px;
    }
  }
}
