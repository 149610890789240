
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.verify-account-page {
  .subtitle:not(:last-child) {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .link-text {
    color: $blue;
  }

  .verify-account-form-container {
    margin-top: 30px;
  }

  .bankid-button-container {
    height: 100%;
    align-items: center;
    margin-top: 60px;
  }

  .verify-form {
    max-width: 300px;

    .resend-code {
      @media only screen and (max-width: 320px) {
        margin-left: -40px;
      }
    }
  }

  .no-thanks-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    text-decoration: underline;
  }

  .rs-form {
    .rs-submit-btn-container {
      justify-content: center;
      padding-bottom: 10px;
    }
  }
}
