
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.about-us-banner-component {
  height: 539px;
  width: 100%;
  margin: 0 auto 3rem 0;
  position: relative;
  background-size: cover;
  background-position: 0 50%;
  padding: 0 2rem;
  background-repeat: no-repeat;

  @include until($tablet) {
    height: auto;
    padding-top: 200px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    background-position: 74% -170px;
    position: relative;
  }

  @include mobile {
    background-position: 0 0;
    background-size: contain;
  }

  @media (min-width: 600px) AND (max-width: 768px) {
    background-size: cover;
    background-position: 74% -70px;
  }

  .container {
    @include until($tablet) {
      padding: 0 2rem;
      background: white;
      position: static;
    }
  }

  .container,
  .columns {
    height: 100%;

    @include until($tablet) {
      height: auto;
    }
  }

  .left-side-container-column {
    height: 100%;
    max-width: 50%;
    margin-bottom: 0.5rem;

    @include until($tablet) {
      width: 100%;
      max-width: 100%;
    }
  }

  .left-side-container {
    position: relative;
    height: 100%;
    display: flex;
    max-width: 505px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    p {
      margin: 0;
      color: black;

      &:last-child {
        margin-top: 0.5rem;
      }
    }

    @include until($tablet) {
      max-width: 100%;
      width: 100%;
      align-items: center;
      position: static;

      h1 {
        position: absolute;
        top: 60px;
        left: 21px;
        max-width: 50%;
        text-align: left;
      }

      p {
        text-align: center;
        padding-top: 1rem;
      }
    }

    h1 {
      margin-bottom: 1rem;
      width: 100%;

      @include mobile {
        font-size: 140%;
      }
    }
  }
}
