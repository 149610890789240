
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.key-benefits-component {
  padding-bottom: 4rem;

  h4 {
    width: 100%;
  }

  .expandable-list-component {
    ul {
      min-height: 224px;
    }

    .expandable-list-component-content {
      &.top-panel {
        min-height: 350px;
      }
    }

    .expandable-list-component-content.list-only {
      &.top-panel {
        min-height: 280px !important;
      }
    }

    @include until($desktop) {
      margin: 0 auto 0 auto;
    }
  }
}
