
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.service-block-container {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;

  @include tablet {
    margin: 2rem 0;
  }
  @include mobile {
    margin: 3rem 0;
  }

  .service-block-box {
    padding: 1rem 0;
  }

  &.active {
    .service-block {
      @include rsdropshadow();
      cursor: default;

      p {
        color: $red;
      }

      .icon-placeholder img {
        filter: invert(8%) sepia(56%) saturate(15406%) hue-rotate(355deg) brightness(63%) contrast(94%);
      }
    }
  }

  .service-block {
    width: 250px;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include border-radius(30px);
    background: white;
    cursor: pointer;
    padding: 1rem;
    cursor: pointer;
    transition: all 0.3s;
    max-width: 200px;

    p {
      color: $grey-dark;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &:hover,
    &.active {
      @include rsdropshadow();

      p {
        color: $red;
      }

      .icon-placeholder img {
        filter: invert(8%) sepia(56%) saturate(15406%) hue-rotate(355deg) brightness(63%) contrast(94%);
      }
    }

    .icon-placeholder {
      width: 60px;
      height: 60px;

      img {
        filter: invert(28%) sepia(5%) saturate(335%) hue-rotate(169deg) brightness(95%) contrast(84%);
      }
    }
  }
}
