
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.homepage-banner-component {
  height: 539px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  background-size: cover;
  background-position: 0 0;
  padding: 0 2rem;
  background-repeat: no-repeat;

  @include until($desktop) {
    height: auto;
    padding-top: 200px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    background-position: 100% 0;
  }

  @include until($desktop) {
    background-position: 74% 0;
  }

  .container {
    @include until($desktop) {
      padding: 0 2rem;
      background: white;
    }
  }

  .container,
  .columns {
    height: 100%;

    @include until($desktop) {
      height: auto;
    }
  }

  .left-side-container-column {
    height: 100%;
    margin-bottom: 0.5rem;
  }

  .left-side-container {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    p {
      margin-top: 0;
    }

    @include until($desktop) {
      align-items: center;

      h1,
      p {
        text-align: center;
      }
    }

    .banner-actions {
      bottom: 2rem;
      height: auto;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: flex-start;

      @include until($desktop) {
        flex-direction: row;
        justify-content: center;

        a {
          &:first-child {
            margin-right: 2rem;
          }
        }
      }

      @include until($tablet) {
        flex-direction: column;
        justify-content: center;
        bottom: 0;
        margin-top: 2rem;
      }

      a {
        @include mobile {
          width: 100%;
        }

        button {
          &:first-child {
            margin-right: 2em;

            @include mobile {
              margin-bottom: 2em;
              width: 100%;
            }
          }
        }
      }
    }

    h1 {
      margin-bottom: 1rem;
      width: 100%;
    }

    h1,
    h4 {
      @include until($desktop) {
        text-align: center;
      }
    }
  }
}
