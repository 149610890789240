
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.alternative-deposits-services {
  padding: 32px $page-section-padding-side $page-section-padding-bottom $page-section-padding-side;
  background: $white-bis;

  .container {
    margin-top: 0.8rem;
  }

  .column-service-container {
    .section-icon {
      &.fractionalize-icon {
        max-width: 81px;
      }
    }

    p,
    section-icon {
      color: rgba(74, 74, 74, 0.75);
      margin: 0;
    }
  }
}
