
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.formatmoney-wrapper {
  .currency {
    margin: 0 0.25rem 0 0;
  }

  .total-currency {
    @extend .currency;
  }
}
