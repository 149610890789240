/* ==========================================================================
Elements
========================================================================== */

// This imports are necessary for storybook to load the component
@import './../variables/theme.scss';
@import './../variables/mixins.scss';

fieldset {
  min-width: 200px;
  width: 100%;

  &[disabled] {
    .button {
      &.is-primary:not(.is-inverted) {
        @include primary-button($blue, false, true);
      }

      &.is-primary.is-inverted {
        @include primary-button($blue, true, true);
      }
    }
  }
}

.icon-button {
  @include icon-button($grey-darker, black);

  &.is-primary {
    @include icon-button($blue, $blue-vivid);
  }
}

article.message {
  border-radius: 0;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &:last-of-type {
    margin-bottom: 3rem;
  }

  .message-body {
    padding: 11px 10px;
    border-radius: 0;
  }

  .message-header {
    border-radius: 0;
  }

  &.is-danger {
    p {
      color: $red-dark;
    }
  }

  p {
    margin: 0;
  }
}

// Buttons
.rs-link {
  color: $blue;
  cursor: pointer;
  @include transition(all 0.3s);

  &:hover {
    color: $blue-dark;
    p {
      color: $blue-dark;
    }
  }

  p {
    @include transition(all 0.3s);
    color: $blue;
    font-weight: bold;
  }
}

.button {
  &.is-primary {
    &.is-loading {
      &.is-inverted {
        &:after {
          border-color: $blue $blue #fff #fff !important;
        }
      }
      &:not(.is-inverted) {
        &:after {
          border-color: $blue $blue #fff #fff !important;
        }
      }
    }

    &:not(.is-inverted) {
      @include primary-button($blue);
    }

    &.is-inverted {
      @include primary-button($blue, true);

      &.is-loading {
        &::after {
          border-color: $blue;
        }
      }
    }
  }

  &.is-danger {
    &.is-loading {
      &.is-inverted {
        &:after {
          border-color: #fff #fff $red $red !important;
        }
      }
      &:not(.is-inverted) {
        &:after {
          border-color: #fff #fff $red $red !important;
        }
      }
    }

    &:not(.is-inverted) {
      @include primary-button($red);
    }

    &.is-inverted {
      @include primary-button($red, true);
    }
  }

  &.is-success {
    &.is-loading {
      &.is-inverted {
        &:after {
          border-color: #fff #fff $green $green !important;
        }
      }
      &:not(.is-inverted) {
        &:after {
          border-color: #fff #fff $green $green !important;
        }
      }
    }

    &:not(.is-inverted) {
      @include primary-button($green, false);
    }

    &.is-inverted {
      @include primary-button($green, true);
    }
  }

  &.is-default {
    &:not(.is-inverted) {
      @include primary-button($grey-darker, false);
    }

    &.is-inverted {
      @include primary-button($grey-darker, true);
    }
  }

  &.is-text {
    @include text-button($blue-dark);

    &.is-danger {
      @include text-button($red);
    }

    &.is-success {
      @include text-button($green);
    }
  }
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

// Dividers & Spacers
.divider {
  height: 2px;
  background: $primary;
  width: 60px;
  margin: 25px auto 40px;
}

.spacer {
  height: 2px;
  margin: 1rem auto;
}

.tag {
  font-size: $is-size-6;
  font-family: $family-bold;
}

.tag:not(body) {
  font-weight: 700;
  font-size: 0.8rem;
}

.tag:not(body).is-info {
  background-color: $blue;
}

// Used to be <hr>, but under html5 it's defined as a semantic topic separator. Using <div> instead
.separator {
  background-color: $blue-white;
  height: 1px;
}

// Box
.box {
  background-color: #ffffff;
  @include transition(all $transition-length ease);
  border-radius: 3px;

  .box:hover {
    box-shadow: 1px 1px 6px 3px #eee;
  }
}

// Table
.table {
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  th {
    padding: 0.5rem 1rem 0.5rem 0;
  }
  thead th {
    text-transform: uppercase;
    font-size: 0.7rem;
    border-width: 0 0 2px;
    color: #777;
    letter-spacing: 0.1rem;
    font-weight: 300;
    padding: 0.75rem 1rem;
  }
  td {
    padding: 0.5rem 1rem 0.5rem777 0;
  }
}

.table.is-transparent {
  box-shadow: none;
  background-color: transparent;
  td {
    border: none;
  }
}

.ReactModal__Overlay {
  @media (max-width: 768px) {
    padding: 20px;
  }
}

.modal {
  display: block;
}

// Modals
.modal-background {
  background-color: rgba(48, 63, 92, 0.85);
}

.modal-card-head,
.modal-card-foot {
  background-color: $white-bis;
  width: 100%;

  .is-flex {
    width: 100%;
  }
}

.modal-card-head {
  border-bottom: 0px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 1rem 1rem 0.5rem;
  display: block;
}

.modal-card-foot {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top: 0px;
}

.modal-content,
.modal-card {
  margin: 0;
  max-height: none;
}

.modal-content {
  background: $white;
  padding: 1.5em;
  line-height: 1.5em;
}

.content figure {
  margin-left: 0;
  margin-right: 0;
  text-align: left;
}

@media only screen and (max-width: 1025px) {
  .modal-card-body {
    padding: 0;
  }
}

// Tooltips
// (source: https://chrisbracco.com/a-simple-css-tooltip/)

[data-tooltip],
.tooltip {
  position: relative;
  cursor: pointer;
}

/* Base styles for the entire tooltip */
[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after {
  position: absolute;
  visibility: hidden;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    -webkit-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -moz-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    -moz-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  pointer-events: none;
}

/* Show the entire tooltip on hover and focus */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after {
  visibility: visible;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

/* Base styles for the tooltip's directional arrow */
.tooltip:before,
[data-tooltip]:before {
  z-index: 1001;
  border: 6px solid transparent;
  background: transparent;
  content: '';
}

/* Base styles for the tooltip's content area */
.tooltip:after,
[data-tooltip]:after {
  z-index: 1000;
  padding: 1rem;
  width: 260px;
  background-color: lighten($color: $grey-lighter, $amount: 3);
  color: $blue;
  font-weight: bold;
  content: attr(data-tooltip);
  font-size: 14px;
  line-height: 1.2;
  white-space: pre-wrap;
  text-align: left;
  @include rslightshadow();
}

/* Directions (top) */

[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after,
.tooltip-top:before,
.tooltip-top:after {
  bottom: 100%;
  left: 50%;
}

[data-tooltip]:after,
.tooltip-top:after {
  text-align: center;
}

/* Direction (bottom) */
.tooltip-bottom:before,
.tooltip-bottom:after {
  top: 100%;
  bottom: auto;
  left: 50%;
}

[data-tooltip]:before,
.tooltip:before,
.tooltip-top:before {
  margin-left: -6px;
  margin-bottom: -12px;
  border-top-color: lighten($color: $grey-lighter, $amount: 3);
}

.tooltip-bottom:before {
  margin-top: -12px;
  margin-bottom: 0;
  border-top-color: transparent;
  border-bottom-color: lighten($color: $grey-lighter, $amount: 3);
}

[data-tooltip].is-red:after {
  color: $red;
}

[data-tooltip].is-yellow:after {
  color: $orange;
}

[data-tooltip].is-green:after {
  color: $green;
}

/* Horizontally align top/bottom tooltips */
[data-tooltip]:after,
.tooltip:after,
.tooltip-top:after {
  margin-left: -120px;
}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after,
.tooltip-top:hover:before,
.tooltip-top:hover:after,
.tooltip-top:focus:before,
.tooltip-top:focus:after {
  -webkit-transform: translateY(-12px);
  -moz-transform: translateY(-12px);
  transform: translateY(-12px);
}

.tooltip-bottom:hover:before,
.tooltip-bottom:hover:after,
.tooltip-bottom:focus:before,
.tooltip-bottom:focus:after {
  -webkit-transform: translateY(12px);
  -moz-transform: translateY(12px);
  transform: translateY(12px);
}

.size-12 {
  font-size: 12px;
}

.steps {
  .step-item {
    .step-title {
      margin: 0;
      font-size: 15px !important;
    }

    .step-details {
      font-size: 14px !important;
    }

    .step-marker {
      font-size: 14px !important;
    }
  }
}

hr {
  width: 100%;
  margin: 2rem 0;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.roman-type-font {
  font-family: none;
}

.width100 {
  width: 100%;
}

.h-space-between {
  justify-content: space-between;
}

.h-flex-end {
  justify-content: flex-end;
}

.h-flex-center-mobile {
  @media (max-width: 768px) {
    justify-content: center;
  }
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex1 {
  flex: 1;
}

.cursor-pointer {
  cursor: pointer;
}

ul {
  list-style-type: disc;
}

.Toastify {
  &__toast {
    white-space: pre-line;
    @include border-radius();

    &--error {
      background-color: $red;
    }

    &--success {
      background-color: $green;
    }

    &--info {
      background-color: $blue;
    }
  }
}

// BULMA NOTIFICATION
.notification {
  p {
    font-size: $is-size-5;
    color: $yellow-dark;
  }
}

// BULMA Image

// BULMA TAGS
.tag:not(body) {
  @include border-radius();

  &.is-warning {
    border: 1px solid $yellow;
    background-color: $yellow-light;
    color: $yellow-dark;
  }

  &.is-primary {
    color: white;
    background-color: $green;
  }

  &.is-info {
    background-color: $blue;
  }

  &.is-black {
    background-color: black;
  }
}

.clear-both {
  clear: both;
}

.timeline {
  .timeline-item {
    .timeline-marker {
      &.is-primary {
        background-color: $blue !important;
        border: 0;
      }
    }

    .timeline-content {
      padding: 14px 0 0 2em;
      p {
        margin: 0;

        &.heading {
          font-size: 11px;
          color: $grey;
        }
      }
    }
  }
}

.general-error-message {
  left: 0;
  bottom: -37px;
  width: 100%;
  color: $red;
  text-align: right;
  font-weight: bold;
  font-size: $is-size-6;
  padding: 0;
  min-height: 25px;
  padding: 5px 0 0 0;

  &.text-align-center {
    text-align: center;
  }

  .message {
    opacity: 0;
    @include transition(opacity $transition-length);

    &.show {
      opacity: 1;
    }
  }
}

/**
*** Color classes
*/

.is-color-blue {
  color: $blue;
}

.is-color-grey-dark {
  color: $grey-darker;
}

.is-color-green {
  color: $green;
}

.is-color-red {
  color: $red;
}

.is-size-0 {
  font-size: $is-size-0 !important;
}

.is-size-1 {
  font-size: $is-size-1 !important;
}
.is-size-2 {
  font-size: $is-size-2 !important;
}
.is-size-3 {
  font-size: $is-size-3 !important;
}
.is-size-4 {
  font-size: $is-size-4 !important;
}
.is-size-5 {
  font-size: $is-size-5 !important;
}
.is-size-6 {
  font-size: $is-size-6 !important;
}
.is-size-7 {
  font-size: $is-size-7 !important;
}

.is-fullwidth {
  width: 100% !important;
}

.is-fullheight {
  height: 100% !important;
}

.switch[type='checkbox'] + label {
  position: relative;
  top: -5px;
}

.reacttooltip {
  overflow: visible;
}
