
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.realstocks-services-page {
  .rs-list {
    max-width: 400px;
  }

  .rs-secondary-nav-menu {
    a {
      text-transform: uppercase !important;
    }
  }

  .img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    img {
      border: 10px solid $white-bis;
      max-width: 370px;
      image-rendering: -moz-crisp-edges; /* Firefox */
      image-rendering: -o-crisp-edges; /* Opera */
      image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
      image-rendering: crisp-edges;
      -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
    }
  }

  .section {
    padding: $page-section-padding;

    &.is-grey {
      background-color: $white-bis;
    }

    &.text-bottom {
      padding-bottom: 15px;
    }

    .section-footer {
      margin-top: 35px;
    }

    .video-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    h4 {
      color: $grey-dark;
    }

    .list-container {
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        font-weight: bold;
      }
    }
  }

  .container-centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
