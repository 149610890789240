
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.rs-mobile-overlay {
  width: 100vw;
  height: 120vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  top: -85px;
  opacity: 1;

  @include transition(all, $transition-length);
}

.rs-overlay {
  width: 100vw;
  height: 120vh;
  position: fixed;
  z-index: 99999;
  top: -85px;
  opacity: 1;
  background-color: transparent;

  @include transition(all, $transition-length);
}
