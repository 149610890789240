
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.homepage-what-we-do-component {
  padding: $page-section-padding;

  .video-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .list-container {
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-weight: bold;
    }
  }

  h3 {
    &.what-we-do-section-headline {
      margin: 0 auto;
      padding: 0 0 5rem 0;
    }
  }

  .advantages-panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 422px;
    position: relative;
    top: -2em;

    p {
      margin: 1rem 0 0;

      &.extra-bold:not(:first-child) {
        padding-top: $gapPadding;
      }
    }
  }

  .how-it-works-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
