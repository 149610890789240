
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
// @import './../../../../node_modules/bulma/sass/utilities/mixins.sass';
@import './../../styles/variables/theme.scss';
@import './../../styles/variables/mixins.scss';

@mixin table-border() {
  border-bottom: 1px solid $grey-light;
}

.rs-table-component {
  header {
    padding: 0;
  }

  div[role='row'] {
    & > div:first-child {
      .reacttooltip {
        background: red;
      }
    }
  }

  .rdt_TableHead {
    z-index: 1;

    .rdt_TableHeadRow {
      @include transition(all, 0.3s);
      @include rsdropshadow();
      @include border-radius();

      .fa-sort-amount-down {
        width: $is-size-4 !important;
        position: relative;
        left: 5px;
      }

      div[role='columnheader'] {
        &:hover,
        &:focus,
        &:active {
          &:not(.rdt_TableCol_Sortable) {
            color: white;
          }
          &.rdt_TableCol_Sortable {
            color: $blue;
          }
        }
      }
    }
  }

  .rdt_TableHeader {
    display: none;
  }

  .rs-table-header {
    display: flex;
    justify-content: space-between;
    width: 100%;

    & > :first-child {
      width: 100%;
    }

    h3 {
      margin: 0;
    }
  }

  .is-income,
  .is-expense {
    font-weight: bold;
  }

  .is-income {
    color: darken($green, 10%);
  }

  .is-expense {
    color: $red;
  }

  .pill-success,
  .pill-warning,
  .pill-danger {
    padding: 4px 10px;
    border-radius: 5px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }

  .rdt_TableHeader {
    h3 {
      margin: 0;
    }
  }

  .pill-success {
    background-color: #59969a;
    color: white;
  }
  .pill-danger {
    background-color: #bf262b;
    color: white;
  }
  .pill-warning {
    background-color: #e3b204;
    color: $text-dark;
  }

  .rdt_TableRow {
    min-height: 40px;
    border-bottom-color: $grey-light !important;

    // expanded row btn
    button[aria-label='Expand Row'],
    button[aria-label='Collapse Row'] {
      &:focus,
      &:hover,
      &:active {
        background-color: transparent !important;
      }

      svg {
        color: $blue;
      }
    }

    button[aria-label='Collapse Row'] {
      svg {
        @include rotate(90);
      }
    }
  }

  button[aria-disabled='true'] {
    svg {
      visibility: hidden;
    }
  }

  #column-button[role='columnheader'] {
    width: 100%;
    justify-content: flex-end;
    margin-right: 16px;
  }

  .has-table-header {
    padding-bottom: 20px !important;
    header {
      min-height: 0px !important;
    }
  }

  .rdt_TableHeader:first-child {
    color: $grey-darker;
    font-weight: 100;
    padding-left: 0;
  }

  .table-extended-no-header {
    .rdt_TableHeader:first-child {
      min-height: fit-content;
    }
  }

  .rs-table-no-data-component {
    padding: 12px 0;
    border-bottom: 1px solid black;
    width: 100%;
    text-align: center;
    border-bottom-color: rgba(0, 0, 0, 0.12);
  }

  .rs-table-no-data-component-standalone {
    padding: 12px 0;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    width: 100%;
    text-align: center;
    border-top-color: rgba(0, 0, 0, 0.12);
    border-bottom-color: rgba(0, 0, 0, 0.12);
  }

  .rs-table-filter-component {
    label {
      margin-right: 8px;
    }

    input {
      min-width: 250px;
    }

    button {
      padding: 0;
      min-width: unset;
    }
  }

  .rs-table-component {
    h3 {
      margin-bottom: 1rem;
    }

    h4 {
      opacity: 0.7;
    }

    .no-data-available {
      padding: 10px 0;
      border: 2px dashed $grey-light;
      margin: 20px 0 40px 0;
    }

    .rs-table {
      display: flex;
      flex-direction: column;
      box-shadow: none;
      padding-bottom: 60px;

      &.is-empty {
        padding-bottom: 0;
      }

      td {
        word-break: break-word;
      }

      &.is-hoverable {
        tbody {
          tr:not(.is-selected) {
            @include transition(background-color $transition-length);
            cursor: pointer;

            &:hover {
              background-color: white;
              z-index: 10;
            }
          }
        }
      }

      &.is-fullwidth {
        min-width: 100%;
        overflow-y: hidden;
        overflow-x: auto;
      }

      thead,
      tbody {
        tr {
          display: flex;
          flex-direction: row;
          width: 100%;
        }

        th,
        td {
          flex: 1;
          border: none;
        }
      }

      thead {
        border-top: none;
        border-left: none;
        border-right: none;

        th {
          min-width: 100px;
          font-weight: 900;
          color: black;
          text-transform: uppercase;
          display: flex;
          align-items: flex-end;
          padding: 0;
          border: none;
          padding: 12px 0;
          justify-content: flex-end;
          border-top: none;
          border-left: none;
          border-right: none;

          @include table-border();

          p {
            font-weight: 900;
            color: black;
            text-transform: uppercase;
            margin: 0;
            padding: 0;
          }
        }

        tr {
          th {
            &:first-child {
              justify-content: flex-start;
              p {
                padding-left: 12px;
              }
            }
          }
        }
      }

      tbody {
        tr {
          background-color: white;
          border: none;

          &:last-child {
            td {
              border-bottom-width: 1px;
            }
          }

          td {
            min-width: 100px;
            color: black;
            display: flex;
            align-items: center;
            padding: 0 15px 0 0;
            justify-content: flex-end;
            border-top: none;
            border-left: none;
            border-right: none;
            @include table-border();
            padding: 12px 0;

            &:first-child {
              justify-content: start;

              p {
                padding: 12px;
              }
            }
          }

          &:first-child {
            justify-content: start;
          }
        }
        .empty-row {
          td {
            width: 100%;
            flex-grow: 1 !important;
            justify-content: center !important;
          }
        }
        .loading-row {
          td {
            width: 100%;
            min-height: 25px;
            flex-grow: 1 !important;
            justify-content: center !important;
          }
        }
      }
    }
  }

  .no-action-button {
    thead tr th,
    tbody tr td {
      &:last-child {
        flex-grow: 1 !important;
      }
    }
    td,
    th {
      flex-grow: 1 !important;
    }
  }
}
