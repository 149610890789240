
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
.login-with-bankid-page {
  padding-top: 20px;
  max-width: 600px !important;

  form {
    padding: 60px 40px 70px;
  }

  .is-6 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
